.App {
  text-align: center;
}

.App-logo {
  animation: App-logo-spin infinite 20s linear;
  height: 40vmin;
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

.ant-layout-sider {
  background: #F0F2F5;
}

.ant-layout-sider-trigger {
  background: #5B7E91;
}

.ant-layout-sider-children .ant-menu {
  background: #5B7E91;
}
    
.ant-menu.ant-menu-sub.ant-menu-vertical {
  background: #F0F2F5;
  color: rgba(0,0,0,.85);
}

.ant-menu-inline .ant-menu-submenu-title {
  color:#FFFFFF;
}

.ant-menu-sub.ant-menu-inline > .ant-menu-item {
  height: 48px;
  line-height: 48px;
}

.ant-menu-dark .ant-menu-inline.ant-menu-sub {
  background: #F0F2F5;
  -webkit-box-shadow: none;
  box-shadow: none;
}

.ant-menu-dark .ant-menu-sub {
  background: #F0F2F5;
}

.ant-menu-dark .ant-menu-item {
  color: rgba(0,0,0,.85);
}

.ant-menu-inline .ant-menu-item {
  margin-top: 0px;
  margin-bottom: 0px;
}

.ant-menu.ant-menu-dark .ant-menu-sub .ant-menu-item-selected {
  background-color: #A2D7DD;
  color:#FFFFFF;
}

.ant-menu-submenu-popup.ant-menu-dark .ant-menu-item-selected {
  background-color: #A2D7DD;
  color:#FFFFFF;
}

.department-note .icon, .department-note .note-content {
  float: left;
}

.department-note .icon {
  font-size: 20px;
  margin-right: 10px;
  padding-top: 5px;
}

.attention .icon-warning,
.attention .attention-content,
.upload .button-upload,
.upload .note-button,
.help .icon-question,
.help .text-help {
  float: left;
}

.upload .note-button,
.help .text-help {
  line-height: 30px;
}
.attention .icon-warning {
  font-size: 20px;
  margin-right: 10px;
}

.upload .button-upload {
  width: 130px;
  height: 35px;
  background-color: #4490F7;
  color: #ebedef;
  margin-right: 10px;
}

.help .icon-question {
  font-size: 30px;
  margin-right: 11px;
  opacity: 0.15;
}

.attention, .upload {
  margin-top: 10px;
  margin-bottom: 30px;
}

.attention, .upload-title, .help .text-help {
  color: rgba(0,0,0,.85);
  font-weight: 700;
}

.ant-result-subtitle {
  color: rgba(0,0,0,.85);
}

.upload-title, .help {
  margin-top: 30px;
  margin-bottom: 15px;
}

.ant-upload.ant-upload-drag .ant-upload-btn {
  height: 150px;
}

.wrap-card {
  padding-left: 25px;
  padding-right: 25px;
}

.ant-upload-list-picture .ant-upload-list-item {
  height: 130px
}

.ant-upload-list-picture .ant-upload-list-item-thumbnail img {
  width: 110px;
  height: 110px;
}

.ant-upload-list-picture .ant-upload-list-item-thumbnail {
  width: 110px;
  height: 110px;
}

.ant-upload-list-picture .ant-upload-list-item-name {
  padding-left: 115px;
  line-height: 110px;
}

/** Custom Card Ant */
.ant-card-head {
  border-bottom: 1px solid #e8e8e8;
  border-radius: 2px 2px 0 0;
}

/** Custom Result Ant */
.ant-result {
  background: #fafafa;
  border: 1px dashed #d9d9d9;
  border-radius: 4px;
  margin-bottom: 16px;
  padding: 8px;
}
.ant-result-icon, .mb-8 {
  margin-bottom: 8px;
}
.ant-result-icon > .anticon {
  font-size: 36px;
}
.ant-result-title {
  font-size: 16px;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}
